@import "palettes";
@import "theme";
@import "mixins";

h1,
h2,
h3,
h4 {
  font-family: $sans;
  font-weight: $medium;
  line-height: $spacing - 0.3;
  margin: 20px 0 20px 0;
  @include trim-v-margins;
  padding: 10px 0; 
}

h1 {
  font-size: 1.4rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;

  & + p {
    margin-top: 20px;
  }
}

h2 {
  font-size: 1.3rem;
  letter-spacing: 1px;
  text-align: center;
}

h3 {
  font-size: 1.2rem;
  text-align: center;
}

h4 {
  font-size: 1rem;
  text-align: left;
}
